
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import type { QuerySnapshot } from "@firebase/firestore/lite";
import Card from "@/components/Card.vue";

type Query = {
  all?: string;
  from: string;
  to: string;
  date: string;
  type: string;
};

export default defineComponent({
  components: { Card },
  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const route = useRoute();
    const data: Ref<any> = ref([]);
    const loading = ref(true);
    const dir = reactive({ from: "", to: "" });

    const searchProps = computed(() => route.query as Query);

    onMounted(async () => {
      dir.from = await store.dispatch("getTownName", {
        id: searchProps.value.from,
        locale: locale.value,
      });
      dir.to = await store.dispatch("getTownName", {
        id: searchProps.value.to,
        locale: locale.value,
      });
    });

    watch(searchProps, async () => {
      if (searchProps.value.all === "true") {
        loading.value = true;
        const querySnapshot: QuerySnapshot = await store.dispatch(
          "getResults",
          {}
        );
        querySnapshot.forEach((doc) => {
          const docData = { ...doc.data(), from: dir.from, to: dir.to };

          data.value.push(docData);
        });
        console.log(data.value);
        loading.value = false;
      }
    });

    onBeforeMount(async () => {
      const querySnapshot: QuerySnapshot = await store.dispatch(
        "getResults",
        searchProps.value
      );

      querySnapshot.forEach(async (doc) => {
        const docData = { ...doc.data(), from: dir.from, to: dir.to };

        data.value.push(docData);
      });
      loading.value = false;
    });

    return { t, data, loading };
  },
});
