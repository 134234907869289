<template>
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    version="1"
    viewBox="0 0 48 48"
    enable-background="new 0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#fff"
      d="M39.1,7l-3.7,0C22.2,7.2,7.1,24.1,7,35.4l0,3.7c0,1,0.8,1.9,1.9,1.9l7.5-0.1c1,0,1.9-0.9,1.9-1.9l0.2-8.2 l-4.7-4c0-2.6,10.5-13.1,13.2-13.2l4.3,4.7l7.9-0.2c1,0,1.9-0.9,1.9-1.9L41,8.9C41,7.8,40.2,7,39.1,7z"
    ></path>
  </svg>
</template>