
import { computed, defineComponent } from "vue";
import ThePhoneAlt from "@/components/SVG/ThePhoneAlt.vue";
import TheTime from "@/components/SVG/TheTime.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { ThePhoneAlt, TheTime },
  props: {
    img: {
      type: String,
      default: "",
    },
    price: { type: String, required: true },
    time: {
      type: String,
      default: new Date().getHours().toString(),
    },
    fullname: { type: String, default: "" },
    date: { type: Date },
    rating: { type: String },
    from: { type: String },
    to: { type: String },
    phone: { type: String },
    free: { type: Boolean, default: false },
  },
  setup(props) {
    const { t } = useI18n();
    const price = computed(() => parseFloat(props.price).toLocaleString());

    return { formattedPrice: price, t };
  },
});
